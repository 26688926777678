<template>
  <div class="popup_wrap" style="height: 400px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">PORT Infomation</h1>
      <div class="content_box">
        <table>
          <colgroup>
            <col width="130px"><col width="170px"><col width="130px"><col width="170px">
            <col width="130px"><col width="170px"><col width="130px"><col width="170px">
          </colgroup>
          <thead>
            <tr class="ETA_ETD_under_table_tr">
              <th>구분</th>
              <th>정보</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key, idx) in parentInfo.focusPort"
                :key="idx"
            >
              <td class="focus1_table">{{ item.name }}</td>
              <td class="focus2_table">{{ item.ctrCd }}{{ item.portNm }}{{ item.cyAddr }}{{ item.hmpgUrlAddr }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <br />
        <table>
          <colgroup>
            <col width="130px"><col width="170px"><col width="130px"><col width="170px">
            <col width="130px"><col width="170px"><col width="130px"><col width="170px">
          </colgroup>
          <thead>
            <tr class="ETA_ETD_under_table_tr">
              <th> VSL/VOY(DT) </th>
              <th> Schedule ETA(DT) </th>
              <th> VSL/VOY(DT) </th>
              <th> AIS ETA(DT) </th>
              <th> VSL/VOY(DT) </th>
              <th> Schedule ETD(DT) </th>
              <th> VSL/VOY(DT) </th>
              <th> AIS ETD(DT) </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in parentInfo.portMarker" style="height : 25px"
                :key="idx"
            >
              <td>{{ item.tableVslCdA }} {{ item.tableVoyNoA }}</td><td>{{ item.tableSchEta }}</td>
              <td style="color: #008000">{{ item.tableVslCdC }} {{ item.tableVoyNoC }}</td>
              <td style="color: #008000; border-right: 1px solid #e1e3eb;">{{ item.tableAisEta }}</td>
              <td>{{ item.tableVslCdB }} {{ item.tableVoyNoB }}</td><td>{{ item.tableSchEtd }}</td>
              <td style="color: #008000">{{ item.tableVslCdD }} {{ item.tableVoyNoD }}</td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CargoPortInfoPop',
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {
          portMarker: [],
          focusPort: []
        }
      }
    }
  }
}
</script>
<style scoped>
.popup_wrap {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 900px;
    height: 600px;
}

.focus1_table {
  text-align: center !important;
}

.focus2_table {
  text-align: center !important;
}
</style>
