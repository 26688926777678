var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { height: "400px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("PORT Infomation")]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", [
            _vm._m(0),
            _vm._m(1),
            _c(
              "tbody",
              _vm._l(_vm.parentInfo.focusPort, function (item, key, idx) {
                return _c("tr", { key: idx }, [
                  _c("td", { staticClass: "focus1_table" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("td", { staticClass: "focus2_table" }, [
                    _vm._v(
                      _vm._s(item.ctrCd) +
                        _vm._s(item.portNm) +
                        _vm._s(item.cyAddr) +
                        _vm._s(item.hmpgUrlAddr)
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "ETA_ETD_under_table_tr" }, [
        _c("th", [_vm._v("구분")]),
        _c("th", [_vm._v("정보")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }